<template>
  <div>
    {{ $t('under_construction') }}
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction',
}
</script>
